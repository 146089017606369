<template>
  <article class="section">
    <h2 class="title">Thanks for your order.</h2>

    <p>Your order will be processed and shipped as soon as possible, usually within 5 days.</p>

    <p>If there are any questions, please email contact@scarlattitilt.com.</p>
    <p>&nbsp;
      <br />
      <a class="button is-link" href="/">Return to frontpage</a>
    </p>
  </article>
</template>

<script>
export default {
  mounted () {
  
  }
}
</script>