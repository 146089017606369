<template>
  <article class="section" v-if="orders.length > 0">
    <h2 class="title">Orders</h2>
    <table class="table">
      <thead>
        <th>Order #</th>
        <th>Who</th>
        <th>What</th>
        <th>Amount</th>
        <th>Paid?</th>
        <th>Shipped?</th>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.id }}</td>
          <td>
              {{ order.name }}<br /><span class="is-size-7">{{order.email }} <br />
              {{ order.address1 }} / {{ order.address2 }} / {{ order.city }} / {{ order.state }} / {{ order.postcode }} / {{ order.country }}<br />
             {{order.phone}}</span>
          </td>
          <td>
            {{ order.order_details }}
          </td>
          <td> {{ order.currency }} {{ order.amount }}</td>
          <td> {{ order.stripe_id ? 'yes' : 'no' }}</td>
          <td> {{ order.shipped ? 'yes' : 'no' }}</td>
        </tr>
      </tbody>
    </table>
  </article>
</template>

<script>
export default {

  data() {
    return {
      orders: [],
      apiKey: null
    }
  },
  created () {
    this.apiKey = process.env.VUE_APP_API_KEY
  },
  mounted () {
    this.axios.get('/api/orders', { headers: {"Authorization": "Api-Key " + this.apiKey } })
      .then((response) => {
        this.orders = response.data
      })
  }
}
</script>