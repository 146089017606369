import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Book from '@/components/Book.vue'
import Basket from '@/components/Basket.vue'
import Checkout from '@/components/Checkout.vue'
import Success from '@/components/Success.vue'
import Orders from '@/components/Orders.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/titles/:id',
    name: 'Book', 
    component: Book
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket
  },  
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/thanks',
    name: 'Success',
    component: Success
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
