<template>
  <article class="section" v-if="Object.keys(cart).length > 0 && books.length > 0">
     <h2 class="title">Your shopping basket</h2>

     <div class="columns">
       <div class="column">
         <p>
           Choose your location:
         </p>
         <div class="select">
           <select v-model="locale" @change="changeLocale">
             <option value="USD">United States</option>
             <option value="GBP">United Kingdom</option>
             <option value="CAD">Canada</option>
             <option value="EUR">Europe or the rest of the world</option>
           </select>

         </div>
       </div>
     </div>
    <table class="table">
       <thead>
         <th>Item</th>
         <th>Quantity</th>
         <th>Price</th>
         <th>Subtotal</th>
       </thead>
       <tbody>

         <tr v-for="bookId in Object.keys(cart)" :key="bookId">
           <td>{{ books.filter((x) => x.id == parseInt(bookId))[0].title }}
             <span class="tag is-success" v-if="cart[bookId].discountCode">
               Promotional code: {{ cart[bookId].discountCode }}
             </span>
           </td>
           <td>
             
             {{ cart[bookId].quantity }}
             <p class="control">
               <a class="is-small button" href="#" @click="decCart(bookId)">–</a>
               <a class="button is-small" v-if="cart[bookId].quantity < 10 && !cart[bookId].discountCode" href="#" @click="incCart(bookId)">+</a>
             </p>
           </td>
           <td>
             <span v-show="locale === 'USD'" :class="cart[bookId].discountCode ? 'slashed' : ''">${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'USD')[0].price * 100) / 100).toFixed(2)}}</span>
             <span v-show="cart[bookId].discountCode && locale === 'USD'"><br />${{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>

             <span v-show="locale === 'CAD'" :class="cart[bookId].discountCode ? 'slashed' : ''">CA${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'CAD')[0].price * 100) / 100).toFixed(2)}}</span><span v-show="cart[bookId].discountCode && locale === 'CAD'"><br />CA${{ parseFloat(Math.round(cart[bookId].discountPrice* 100) / 100).toFixed(2) }}</span>

             <span v-show="locale === 'GBP'" :class="cart[bookId].discountCode ? 'slashed' : ''">£{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'GBP')[0].price * 100) / 100).toFixed(2)}}</span><span v-show="cart[bookId].discountCode && locale === 'GBP'"><br />£{{ parseFloat(Math.round(cart[bookId].discountPrice* 100) / 100).toFixed(2) }}</span>

             <span v-show="locale === 'EUR'" :class="cart[bookId].discountCode ? 'slashed' : ''">{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'EUR')[0].price * 100) / 100).toFixed(2)}}€</span><span v-show="cart[bookId].discountCode && locale === 'EUR'"><br />{{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}€</span>

           </td>
           <td>
             <span v-show="locale === 'USD'">
               <span v-if="cart[bookId].discountPrice">${{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
               <span v-else>${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'USD')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
             </span>
             <span v-show="locale === 'CAD'">
               <span v-if="cart[bookId].discountPrice">CA${{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
               <span v-else>
                 CA${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'CAD')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
             </span>
             <span v-show="locale === 'GBP'">
               <span v-if="cart[bookId].discountPrice">£{{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
               <span v-else>£{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'GBP')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
             </span>
             <span v-show="locale === 'EUR'">
               <span v-if="cart[bookId].discountPrice">{{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}€</span>
               <span v-else>{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'EUR')[0].price * cart[bookId].quantity* 100) / 100).toFixed(2) }}€</span>
             </span>
           </td>           
         </tr>
         <tr>
           <td colspan="3">{{ selectedShipping.name }}
             <br />
             <span class="is-size-7">For other shipping types (faster, trackable, insured) please email contact@scarlattitilt.com before ordering.</span>
           </td>
           <td>
             <span v-show="locale === 'USD'">$ </span>
             <span v-show="locale === 'CAD'">CA$</span>
             <span v-show="locale === 'GBP'">£</span>
             {{ parseFloat(Math.round(selectedShipping.price * 100) / 100).toFixed(2) }}
             <span v-show="locale === 'EUR'">€</span>
           </td>
         </tr>
       </tbody>
       <tfoot>
         <th colspan="3"> Total</th>
         <th>
           <span v-if="locale === 'USD'">$</span><span v-if="locale==='GBP'">£</span><span v-if="locale==='CAD'">CA$</span>{{ parseFloat(Math.round(total * 100) / 100).toFixed(2)  }}<span v-if="locale==='EUR'">€</span>
         </th>
       </tfoot>
     </table>
     <div class="columns">
       <div class="column is-one-third">
         <label class="label">Promo code:</label>
         <p class="control">
           If you have a discount or promotion code, enter it here:
         </p>
         <div class="field has-addons">
           <div class="control">
             <input class="input" type="text" :disabled="disabledPromo" v-model="promoCode" />
           </div>
           <div class="control">
             <img v-if="applying" src="@/assets/images/loadcircle.gif" />
             <a  v-show="!disabledPromo && !applying" class="button is-primary" :disabled="promoCode.length < 4" @click="checkPromotion">Apply promotion</a>
           </div>
         </div>
         <div v-show="promoNotFound" class="notification is-warning">
           This promotional code was not found. Please try again.
         </div>
       </div>
     </div>

     <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <p class="control">
            <router-link :to="{name: 'Checkout'}" class="button is-link">Proceed to checkout</router-link>
          </p>
          <p class="control">
            <router-link :to="{name: 'Home'}" class="button is-secondary">Continue shopping</router-link>
          </p>
        </div>
      </div>
    </div>
  </article>
  <article v-else>
    <p>There is nothing in your basket.</p>
    <p>
      <router-link class="button is-link" :to="{name: 'Home'}">Back to the front</router-link>
    </p>
  </article>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data() {
    return {
      applying: false,
      locale: null,
      orderInfo: {},
      submitting: false,
      loading: false,
      promoNotFound: false,
      books: [],
      promoCode: '',
      totalBooks: 0,
      formIncomplete: false,
      rates: [],
      clientReferenceId: null,
      discount: {},
      disabledPromo: false,
      selectedShipping: {},
      stripeItems: [],
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE,
      successUrl: process.env.VUE_APP_BASE_URL + '/thanks',
      cancelUrl: process.env.VUE_APP_BASE_URL + '/checkout',
      total: 0
    }
  },
  name: 'Basket', 
  methods: { 
    checkPrice (locale, bookId) {
      if (this.discount[bookId]) {
        return this.discount[bookId]
      } else {
      return this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === locale)[0].price
      }
    },
    calculateShipping () {
      let numBooks = 0;
      Object.keys(this.$store.state.cart).forEach((b) => {
        numBooks += parseInt(this.$store.state.cart[b].quantity)
      })
      this.totalBooks = numBooks
      
      if (this.totalBooks > 2) {
        this.selectedShipping = this.rates.filter((x) => (x.locale === this.locale) && x.level === 2)[0]
      } else {
        this.selectedShipping = this.rates.filter((x) => (x.locale === this.locale) && x.level === 1)[0]
      }
    },
    changeLocale () {
      this.$store.commit('changeLocale', this.locale)
      this.orderInfo.country = this.localeToISO()
      this.calculateShipping()
      this.checkPromotion()
      this.calcTotal()
    },
    checkPromotion () {
      this.promoNotFound = false

      if (this.promoCode) {
        this.applying = true
        Object.keys(this.$store.state.cart).forEach((bookId) => {
          this.axios.post('/check_promotion/' + bookId, null, { params: { code: this.promoCode }})
            .then((response) => {
              if (response.data.length > 0) {

                // this.stripeItems.delete({sku: this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === this.locale )[0].sku, quantity: this.$store.state.cart[bookId] })
                // this.stripeItems.push({sku: response.data.filter((x) => x.locale === this.locale )[0].sku, quantity: this.$store.state.cart[bookId] })
                this.$store.state.cart[bookId].quantity = 1
                // console.log('setting discount for book ' + bookId + ' to ' + response.data.filter((x) => x.locale === this.locale )[0].price + ' with code ' + this.promoCode)
                this.$store.commit('setDiscount', [bookId, this.promoCode, response.data.filter((x) => x.locale === this.locale )[0].price, response.data.filter((x) => x.locale === this.locale )[0].sku])
              } else {
                this.promoNotFound = true
              }
            })
            .then(() => {
              this.calcTotal()
              this.applying = false
            })
        })
      }
      
    },
    calcTotal () {
      this.total = 0
      this.stripeItems = []
      // console.log(this.$store.state.cart)
      Object.keys(this.$store.state.cart).forEach((bookId) => {
        if (parseInt(this.$store.state.cart[bookId].quantity) > 0) {
          if (this.$store.state.cart[bookId].discountPrice) {
            this.total += this.$store.state.cart[bookId].discountPrice
            this.stripeItems.push({sku: this.$store.state.cart[bookId].sku, quantity: 1 })
          } else {
            this.total += this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === this.locale )[0].price * this.$store.state.cart[bookId].quantity
            this.stripeItems.push({sku: this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === this.locale )[0].sku, quantity: this.$store.state.cart[bookId].quantity })
          }
        }
      })
      this.calculateShipping()
      this.stripeItems.push({ sku: this.selectedShipping.sku, quantity: 1})
      this.total += this.selectedShipping.price
    },
    incCart (bookId) {
      this.$store.commit('addToCart', bookId )
      this.calcTotal()
    },
    decCart (bookId) {
      this.$store.commit('removeFromCart', bookId)
      this.calcTotal()
    },

    localeToISO () {
      if (this.locale === 'USD') {
        return 'US'
      } else if (this.locale === 'GBP') {
        return 'UK'
      } else if (this.locale === 'CAD') {
        return 'CA'
      }
      else { 
        return '' 
      }
    }
  },
  created () {
    this.locale = this.$store.state.locale
    this.orderInfo.country = this.localeToISO()
    this.clientReferenceId = null
  },
  mounted () {
    this.promoCode = this.savedPromoCode
    if (this.savedPromoCode) {
      this.disabledPromo = true
    }
    this.axios.get('/rates')
      .then((response) => {
        this.rates = response.data
        this.selectedShipping = this.rates.filter((x) => x.locale === this.locale)[0]
      })
      .then( () => {
        this.axios.get('/books?withGraph=prices')
          .then((response) => {
            this.books = response.data
            this.loading = false
          })
          .then(() => {
            this.calcTotal()
          })
      })
  },
  computed: {
    ...mapGetters({
      cart: 'getCart',
      savedPromoCode: 'getPromocode'
    })
  }
}
</script>