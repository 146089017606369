<template>
  <section v-if="!loading" class="section">
    <div v-if="apiError" class="notification is-warning">Sorry, the webserver is down. Please contact us at contact@scarlattitilt.com for inquiries.</div>
    <article v-else>
      <div class="columns">
        <div class="column">
          <h2 class="title">Now available!</h2>
        </div>
      </div>
      <div v-for="book in books" :key="book.id" class="columns is-mobile home_book">
        <div class="column is-one-third-tablet is-4-mobile">
          <img :src="book.image_url" />
        </div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <h3 class="title is-size-4 is-size-6-mobile"><em>{{ book.title }}</em></h3>
              <h4 class="subtitle is-size-4 is-size-6-mobile">{{ book.author }}</h4>
            </div>
          </div>
          <div class="columns is-hidden-mobile">
            <div class="column is-one-half">
              <p>{{ book.pagecount }} pages</p>
              <p>Released: {{ moment(book.released).format('DD MMMM YYYY')}}</p>
              <p>ISBN: {{ book.isbn }}</p>
            </div>
          </div>
          <div class="columns is-hidden-mobile">
            <div class="column">
              <p class="is-size-6" v-html="book.description"></p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <router-link tag="button" class="button is-primary" :to="{name: 'Book', params: { id: book.id }}">More info / buy!</router-link>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>

</template>

<script>

export default {

  data() {
    return {
      apiError : false,
      loading: false,
      books: []
    }
  },
  mounted () {
    this.axios.get('/books')
      .then((response) => {
        this.books = response.data
        this.loading = false
      })
      .catch(() => {
        this.apiError = true
      })
  }
}
</script>

