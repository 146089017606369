<template>
  <article class="section"  v-if="Object.keys(cart).length > 0 && books.length > 0">
    <div class="columns">
      <div class="column is-4">
        <table class="table">
           <thead>
             <th>Item</th>
             <th>Quantity</th>
             <th>Price</th>
             <th>Subtotal</th>
           </thead>
           <tbody>

             <tr v-for="bookId in Object.keys(cart)" :key="bookId">
               <td>{{ books.filter((x) => x.id == parseInt(bookId))[0].title }}
                 <span class="tag is-success" v-if="cart[bookId].discountCode">
                   Promotional code: {{ cart[bookId].discountCode }}
                 </span>
               </td>
               <td>
                 
                 {{ cart[bookId].quantity }}

               </td>
               <td>
                 <span v-show="locale === 'USD'" :class="cart[bookId].discountCode ? 'slashed' : ''">${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'USD')[0].price * 100) / 100).toFixed(2) }}</span>
                 <span v-show="cart[bookId].discountCode && locale === 'USD'"><br />${{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2) }}</span>

                 <span v-show="locale === 'CAD'" :class="cart[bookId].discountCode ? 'slashed' : ''">CA${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'CAD')[0].price * 100) / 100).toFixed(2) }}</span><span v-show="cart[bookId].discountCode && locale === 'CAD'"><br />${{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>

                 <span v-show="locale === 'GBP'" :class="cart[bookId].discountCode ? 'slashed' : ''">£{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'GBP')[0].price * 100) / 100).toFixed(2)}}</span><span v-show="cart[bookId].discountCode && locale === 'GBP'"><br />${{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>

                 <span v-show="locale === 'EUR'" :class="cart[bookId].discountCode ? 'slashed' : ''">{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'EUR')[0].price * 100) / 100).toFixed(2)}}€</span><span v-show="cart[bookId].discountCode && locale === 'EUR'"><br />${{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>

               </td>
               <td>
                 <span v-show="locale === 'USD'">
                   <span v-if="cart[bookId].discountPrice">${{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
                   <span v-else>${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'USD')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
                 </span>
                 <span v-show="locale === 'CAD'">
                   <span v-if="cart[bookId].discountPrice">CA${{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
                   <span v-else>
                     CA${{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'CAD')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
                 </span>
                 <span v-show="locale === 'GBP'">
                   <span v-if="cart[bookId].discountPrice">£{{parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}</span>
                   <span v-else>£{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'GBP')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}</span>
                 </span>
                 <span v-show="locale === 'EUR'">
                   <span v-if="cart[bookId].discountPrice">{{ parseFloat(Math.round(cart[bookId].discountPrice * 100) / 100).toFixed(2)}}€</span>
                   <span v-else>{{ parseFloat(Math.round(books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === 'EUR')[0].price * cart[bookId].quantity * 100) / 100).toFixed(2)}}€</span>
                 </span>
               </td>           
             </tr>
             <tr>
               <td colspan="3">{{ selectedShipping.name }}
                 <br />
                 <span class="is-size-7">For other shipping types (faster, trackable, insured) please email contact@scarlattitilt.com before ordering.</span>
               </td>
               <td>
                 <span v-show="locale === 'USD'">$ </span>
                 <span v-show="locale === 'CAD'">CA$</span>
                 <span v-show="locale === 'GBP'">£</span>
                 {{ parseFloat(Math.round(selectedShipping.price * 100) / 100).toFixed(2) }}
                 <span v-show="locale === 'EUR'">€</span>
               </td>
             </tr>
           </tbody>
           <tfoot>
             <th colspan="3"> Total</th>
             <th>
               <span v-if="locale === 'USD'">$</span><span v-if="locale==='GBP'">£</span><span v-if="locale==='CAD'">CA$</span>{{ parseFloat(Math.round(total * 100) / 100).toFixed(2)}}<span v-if="locale==='EUR'">€</span>
             </th>
           </tfoot>
         </table>
       </div>
      <div class="column is-7 is-offset-1">
        <h2 class="subtitle"><strong>Your info:</strong></h2>
        <p> Please enter your information so we can ship the books to you.</p>
        <div class="columns">
          <div class="column">
            <form  v-on:submit.prevent="collectInfo">
              <div class="field">
                <label class="label">Your name</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.name" placeholder="Your name" />
                </div>
              </div>
              <div class="field">
                <label class="label">Your email address</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.email" placeholder="Your email address" />
                </div>
              </div>
              <div class="field">
                <label class="label">Your address</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.address1" placeholder="Address line 1" />
                  <input class="input" type="text" v-model="orderInfo.address2" placeholder="Address line 2" />
                </div>
              </div>
              <div class="field">
                <label class="label">City</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.city" placeholder="City" />
                </div>
              </div>
              <div class="field">
                <label class="label">State or region</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.state" placeholder="State" />
                </div>
              </div>
              <div class="field">
                <label class="label">Postcode/ZIP code</label>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.postcode" placeholder="Postcode" />
                </div>
              </div>
              <div class="field">
                <label class="label">Country</label>
                <p v-if="locale === 'EUR'">If your country is not in this list, please email contact@scarlattitilt.com to figure out shipping costs and arrange an alternate order.</p>
                <div class="select">
                  <select v-model="orderInfo.country" :disabled="locale !== 'EUR'">
                    <option></option>
                    <option value="US" :disabled="locale === 'EUR'">United States</option>
                    <option value="UK" :disabled="locale === 'EUR'">United Kingdom</option>
                    <option value="CA" :disabled="locale === 'EUR'">Canada</option>
                    
                    <option value="AL">Albania</option>
                    <option value="AT">Austria</option>
                    <option value="BE">Belgium</option>
                    <option value="BG">Bulgaria</option>
                    <option value="HR">Croatia</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="EE">Estonia</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                    <option value="GR">Greece</option>
                    <option value="HU">Hungary</option>
                    <option value="IT">Italy</option>
                    <option value="IS">Iceland</option>
                    <option value="IE">Ireland</option>
                    <option value="RS">Kosovo</option>
                    <option value="LV">Latvia</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MK">Macedonia</option>
                    <option value="MT">Malta</option>
                    <option value="ME">Montenegro</option>                    
                    <option value="NL">The Netherlands</option>
                    <option value="NO">Norway</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russia</option>
                    <option value="RS">Serbia</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="ES">Spain</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="UA">Ukraine</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label">Your phone number</label>
                <p>Please provide a phone number for the courier service.</p>
                <div class="control">
                  <input class="input" type="text" v-model="orderInfo.phone" placeholder="Phone" />
                </div>
              </div>                         
            </form>
          </div>
        </div>
        <div class="columns" v-if="formIncomplete">
          <div class="column">
            <div class="notification is-warning">Please fill out the form completely.</div>
          </div>
        </div>
        <div class="columns" v-if="errorSavingOrder">
          <div class="column">
            <div class="notification is-warning">There was an error processing you order. Please try again or email contact@scarlattitilt.com for assistance.</div>
          </div>
        </div>    
        <div class="columns">
          <div class="column">
          <stripe-checkout v-show="!this.submitting"
              ref="checkoutRef"
              :pk="publishableKey"
              :items="stripeItems"
              :clientReferenceId="clientReferenceId"
              :successUrl="successUrl"
              :cancelUrl="cancelUrl"
              :disabled="true"
            >
               <template slot="checkout-button">
                 <div class="field is-grouped">
                   <p class="control">
                     <button class="button is-link" @click="checkout">Proceed to payment</button>
                   </p>
                   <p class="control">
                    <router-link class="button is-secondary" :to="{name: 'Home'}">Back to the front</router-link>
                   </p>
                 </div>
              </template>
          </stripe-checkout>
          <img v-show="this.submitting" src="@/assets/images/loadcircle.gif" />
        </div>
      </div>
    </div>
  </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import { StripeCheckout } from 'vue-stripe-checkout'
export default {

  data() {
    return {
      applying: false,
      locale: null,
      orderInfo: {},
      books: [],
      submitting: false,
      loading: false,
      promoNotFound: false,
      apiKey: null,
      promoCode: '',
      jwt: '',
      formIncomplete: false,
      rates: [],
      clientReferenceId: null,
      discount: {},
      disabledPromo: false,
      errorSavingOrder: false,
      selectedShipping: {},
      stripeItems: [],
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE,
      successUrl: process.env.VUE_APP_BASE_URL + '/thanks',
      cancelUrl: process.env.VUE_APP_BASE_URL + '/',
      total: 0
    }
  },
  name: 'Checkout', 
  components: {
    StripeCheckout
  },
  created () {
    this.locale = this.$store.state.locale
    this.orderInfo.country = this.localeToISO()
    this.clientReferenceId = null
    this.apiKey = process.env.VUE_APP_API_KEY
  },
  mounted () {
    this.promoCode = this.savedPromoCode
    if (this.savedPromoCode) {
      this.disabledPromo = true
    }
    this.axios.get('/rates')
      .then((response) => {
        this.rates = response.data
        this.selectedShipping = this.rates.filter((x) => x.locale === this.locale)[0]
      })
      .then( () => {
        this.axios.get('/books?withGraph=prices')
          .then((response) => {
            this.books = response.data
            this.loading = false
          })
          .then(() => {
            this.calcTotal()
          })
      })
  },
  methods : {
    calcTotal () {
      this.total = 0
      this.stripeItems = []
      // console.log(this.$store.state.cart)
      Object.keys(this.$store.state.cart).forEach((bookId) => {
        if (parseInt(this.$store.state.cart[bookId].quantity) > 0) {
          if (this.$store.state.cart[bookId].discountPrice) {
            this.total += this.$store.state.cart[bookId].discountPrice
            this.stripeItems.push({sku: this.$store.state.cart[bookId].sku, quantity: 1 })
          } else {
            this.total += this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === this.locale )[0].price * this.$store.state.cart[bookId].quantity
            this.stripeItems.push({sku: this.books.filter((x) => x.id == parseInt(bookId))[0].prices.filter((x) => x.currency === this.locale )[0].sku, quantity: this.$store.state.cart[bookId].quantity })
          }
        }
      })

      this.stripeItems.push({ sku: this.selectedShipping.sku, quantity: 1})
      this.total += this.selectedShipping.price
    },
    localeToISO () {
      if (this.locale === 'USD') {
        return 'US'
      } else if (this.locale === 'GBP') {
        return 'UK'
      } else if (this.locale === 'CAD') {
        return 'CA'
      }
      else { 
        return '' 
      }
    },
    checkout () {
      if (this.submitting) {
        return false
      } else {
        this.submitting = true
        this.errorSavingOrder = false
        if (this.validateOrderInfo()) {
          //  POST customer data to API with some sort of auth
          let orderHash = this.orderInfo
          orderHash.customer_reference_id = this.guidGenerator()
          orderHash.amount = this.total - this.selectedShipping.price
          orderHash.shipping = this.selectedShipping.price
          orderHash.currency = this.locale
          orderHash.ordered_at = new Date().toISOString()
          orderHash.order_details = (() => {
            let cartHash = []
            Object.keys(this.$store.state.cart).forEach((bookId) => {
              cartHash.push({ title: this.books.filter((x) => x.id == parseInt(bookId))[0].title,
                discount: this.promoCode,
                shipping: this.selectedShipping.name,
                quantity: this.$store.state.cart[bookId].quantity })
            })
            return { "cart" : cartHash }
          })()
          this.axios.post('/api/order', orderHash, { headers: {"Authorization": "Api-Key " + this.apiKey } })
            .then((response) => {
              if (response.status === 200) {
                this.clientReferenceId = orderHash.customer_reference_id
                this.$refs.checkoutRef.redirectToCheckout()
              } else {
                // couldn't save order locally
                this.errorSavingOrder = true
              }
            })
            .catch(() => {
              this.errorSavingOrder = true
              this.submitting = false
            })

          //  and have webhook put stripe payment ID into orders table at appropriate line

        } else {
          this.formIncomplete = true
          this.submitting = false
        }
      }
    },
    guidGenerator() {
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    },
    validateOrderInfo () {
      if (this.orderInfo.phone && this.orderInfo.city  && this.orderInfo.email  && this.orderInfo.postcode && this.orderInfo.address1 && this.orderInfo.name) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: 'getCart',
      savedPromoCode: 'getPromocode'
    })
  }
}
</script>
