<template>
  <div id="app">
    <nav class="navbar is-spaced is-fixed-top is-light" role="navigation" aria-label="main navigation" id="nav">
      <div class="navbar-brand">
        <router-link :to="{name: 'Home'}" ><img src="./assets/images/stlogo.png" width="27" height="70"></router-link>
        <router-link :to="{name: 'Home'}"  class="navbar-item">
          <span class="is-size-3">Scarlatti Tilt</span>
        </router-link>
        <router-link v-if="Object.keys(cart).length > 0" class="burger  button is-hidden-desktop is-warning" :to="{name: 'Basket'}">{{ Object.keys(cart).length }} book{{ Object.keys(cart).length > 1 ? 's' : ''}} in basket</router-link>
      </div>
      <div class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item is-flex-tablet">Scarlatti Tilt publishes printed material.</div>
          <div class="navbar-item" v-if="Object.keys(cart).length > 0">
            <router-link :to="{name: 'Basket'}" class="button is-primary">{{ Object.keys(cart).length }} book{{ Object.keys(cart).length > 1 ? 's' : ''}} in basket</router-link>
          </div>
        </div>
      </div>
    </nav>
    <section id="main" class="container">
      <router-view :key="$route.fullPath" />
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cart: 'getCart'
    })
  }
}
</script>
<style lang="scss">
@import 'assets/css/scarlatti_tilt.scss';
</style>