import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    getCart: state => state.cart,
    getPromocode (state) {
      let lastCode = ''
      Object.keys(state.cart).forEach((bookId) => {
        if (state.cart[bookId].discountCode) {
          lastCode = state.cart[bookId].discountCode
        }
      })
      if (lastCode) {
        return lastCode
      } else { 
        return ''
      }
    }

  },
  state: {
    locale: 'USD',
    cart: {}
  },
  mutations: {
    changeLocale (state, newLocation) {
      state.locale = newLocation
    },

    setDiscount (state, payload) {
      
      Vue.set(state.cart[payload[0]], 'discountCode', payload[1])
      Vue.set(state.cart[payload[0]], 'discountPrice', payload[2])
      Vue.set(state.cart[payload[0]], 'sku', payload[3])
    },
    addToCart (state, itemId) {
      if (state.cart[itemId]) {
        Vue.set(state.cart[itemId], 'quantity', state.cart[itemId].quantity + 1)
        if (state.cart[itemId].quantity > 10) {
          Vue.set(state.cart[itemId], 'quantity', 10)
        }
      } else {
        Vue.set(state.cart, itemId, {})
        Vue.set(state.cart[itemId], 'quantity',  1)
      }
    },
    removeFromCart (state, itemId) {
      Vue.set(state.cart[itemId], 'quantity', state.cart[itemId].quantity - 1)
      if (state.cart[itemId].quantity == 0) {
        delete state.cart[itemId] 
      }
    }

  },
  actions: {
  },
  modules: {

  }
})
