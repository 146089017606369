<template>
  <article class="section" v-if="!loading && book.prices">
    <div class="columns is-mobile">
      <div class="column is-one-quarter-desktop is-4-mobile">
        <img :src="book.image_url">
      </div>
      <div class="column is-7 is-offset-1">
        <div class="columns">
          <div class="column">
            <h2 class="title is-size-5-mobile">{{ book.title }}</h2>
            <h3 class="subtitle is-size-6-mobile"> {{ book.author.name }}</h3>
          </div>
        </div>
        <div class="columns is-hidden-mobile">
          <div class="column">
            <p class="is-size-7-mobile">Paperback, {{ book.pagecount }} pages</p>
            <p class="is-size-7-mobile">Published: {{ moment(book.released).format('DD MMMM YYYY') }}</p>
            <p class="is-size-7-mobile">ISBN: {{ book.isbn }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-hidden-tablet">
      <div class="column">
        <p class="is-size-7-mobile">Paperback, {{ book.pagecount }} pages</p>
        <p class="is-size-7-mobile">Published: {{ moment(book.released).format('DD MMMM YYYY') }}</p>
        <p class="is-size-7-mobile">ISBN: {{ book.isbn }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p class="is-size-6" v-html="book.description"></p>
      </div>
    </div>
    <div class="columns" v-if="book.author.bio">
      <div class="column">
        <h4 class="subtitle">About the author:</h4>
        <p>{{ book.author.bio }} </p>
        <p>
          <a :href="book.author.website" target="_blank">{{ book.author.website }}</a>
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h4 class="subtitle">Buy {{book.title }}:</h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p>
          Choose your location:
        </p>
        <div class="select">
          <select  v-model="locale" @change="changeLocale">
            <option value="USD">United States</option>
            <option value="GBP">United Kingdom</option>
            <option value="CAD">Canada</option>
            <option value="EUR">Europe or the rest of the world</option>
          </select>
        </div>
      </div>
      <div class="column">
        <p>Price:
        </p>
        <p class="is-size-4">
          <span v-show="locale === 'USD'">${{ book.prices.filter((x) => x.currency === 'USD')[0].price }}</span>
          <span v-show="locale === 'CAD'">CA${{ book.prices.filter((x) => x.currency === 'CAD')[0].price }}</span>
          <span v-show="locale === 'GBP'">£{{ book.prices.filter((x) => x.currency === 'GBP')[0].price }}</span>
          <span v-show="locale === 'EUR'">{{ book.prices.filter((x) => x.currency === 'EUR')[0].price }}€</span>
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <p class="control" v-if="book.sample_url" >
            <a target="_blank" class="button is-link" :href="book.sample_url">Read sample pages</a>
          </p>
          <p class="control">
            <button class="button is-primary" @click="addToBasket">Add to basket</button>
          </p>
          <p class="control" v-if="Object.keys(cart).length > 0">
            <router-link :to="{name: 'Basket'}" class="button is-secondary">View basket</router-link>
          </p>
          <p class="control" v-if="Object.keys(cart).length > 0">
            <router-link :to="{name: 'Home'}" class="button is-secondary">Continue shopping</router-link>
          </p>
          <p class="control" v-else>
            <router-link :to="{name: 'Home'}" class="button is-secondary">Browse other titles</router-link>
          </p>
        </div>
      </div>

    </div>
    <div class="columns space">
      <div class="column">
        <h2 class="subtitle">Also available:</h2>
      </div>
    </div>
    <div class="columns is-mobile">

      <div class="column is-half" v-for="other in books" v-show="other.id !== book.id" :key="other.id">
        <div class="columns">
          <div class="column is-one-quarter is-two-thirds-mobile is-offset-1">
            <img :src="other.image_url">
          </div>
          <div class="column is-one-third">
            <h2 class="subtitle">{{ other.title }}</h2>
            <p class="control">
              <router-link tag="button" :to="{name: 'Book', params: { id: other.id }}"  class="button is-secondary">More info</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      book: {},
      books: [],
      amount: null,
      locale: null,
      selectedPrice: null,

    }
  },
  name: 'Book',
  computed: {
    ...mapGetters({
      cart: 'getCart'
    })
  },
  methods : {
    addToBasket () {
      this.$store.commit('addToCart',  this.book.id)

    },
    changeAmount () {
      this.amount = this.book.prices.filter((x) => x.currency === this.locale)[0].price * 100
      this.sku = this.book.prices.filter((x) => x.currency === this.locale)[0].sku
      this.items = [ {sku: this.sku, quantity: 1 }]
    },
    changeLocale () {
      this.$store.commit('changeLocale', this.locale)
      this.changeAmount()
    }
  },
  created () {
    this.locale = this.$store.state.locale
  },
  mounted () {
    this.axios.get('/books')
      .then((response) => {
        this.books = response.data
      })
    this.axios.get('/books/' + this.$route.params.id)
      .then((response) => {
        this.book = response.data
        this.amount = this.book.prices.filter((x) => x.currency === this.locale)[0].price * 100
        this.loading = false        
      })
  }
}
</script>        